/* eslint-disable */
(function () {
  'use strict';

  function communicationModuleConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('/communication', '/communication/inbox/private');
    $urlRouterProvider.when('/communication/', '/communication/inbox/private');
    $urlRouterProvider.when('/communication/inbox', '/communication/inbox/private');
    $urlRouterProvider.when('/communication/inbox/', '/communication/inbox/private');

    $stateProvider
      .state('root.communication', {
        url: '/communication',
        views: {
          'contentView@root': {
            template: '<communication-root></communication-root>',
          },
        },
        resolve: {
          permissions: function (ACLService) {
            return ACLService.hasPermissionsPromise('general:chat-access');
          },
          lazyLoad: function ($ocLazyLoad, MyLazyLoad) {
            return MyLazyLoad.load($ocLazyLoad, [
              '/app/js/communication/sources.js',
              '/app/js/team/sources.js',
            ]);
          },
          title: function (Tabs) {
            Tabs.setWindowTitle('Communication');
          },
        },
      })
      .state('root.communication.inbox', {
        url: '/inbox/:id',
        views: {
          'content@root.communication': {
            template:
              '<inbox' +
              ' inbox-key="$resolved.state.currentInbox"' +
              ' inboxes="$comRootCtrl.state.inboxes"' +
              ' is-online="$resolved.connectionState.isOnline"' +
              ' is-socket-ready="$comRootCtrl.state.isReady" />',
            controller: function ($stateParams, CommunicationService, ConnectionStatus) {
              CommunicationService.selectInbox($stateParams.id);
              this.state = CommunicationService.getState();
              this.connectionState = ConnectionStatus.state;
            },
            controllerAs: '$resolved',
          },
        },
        resolve: {
          user: function (UserService) {
            return UserService.getPromise();
          },
          communicationServiceReady: function (CommunicationService) {
            return CommunicationService.$initializationPromise;
          },
        },
      })
      .state('root.communication.inbox.channel', {
        url: '/:channelId',
        views: {
          messages: {
            template:
              '<message-list' +
              ' messages="$inboxCtrl.state.channelMessages[$channelDetailCtrl.activeChannel.id]"' +
              ' has-more="$inboxCtrl.state.hasMoreMessages[$channelDetailCtrl.activeChannel.id]"' +
              ' channel="$channelDetailCtrl.activeChannel"' +
              ' is-window-visible="$channelDetailCtrl.pageVisibilityState.isVisible"' +
              ' unread-count="$inboxCtrl.state.unreadCount.getIn([$inboxCtrl.inboxKey, $channelDetailCtrl.activeChannel.id])"' +
              ' user="$inboxCtrl.user"' +
              ' inbox-key="$inboxCtrl.inboxKey"' +
              ' presences="$inboxCtrl.state.presences"' +
              ' read-receipts="$inboxCtrl.state.readReceipts[$channelDetailCtrl.activeChannel.id]"' +
              ' typing-users="$inboxCtrl.state.typingUsers[$channelDetailCtrl.activeChannel.id]"' +
              ' on-channel-leave="$inboxCtrl.leaveChannel($channelDetailCtrl.activeChannel, $inboxCtrl.inboxKey)"' +
              '></message-list>',
            controller: function (
              $scope,
              $state,
              $stateParams,
              CommunicationService,
              PageVisibility,
              activeChannel
            ) {
              if (activeChannel) {
                this.activeChannel = activeChannel;
                this.pageVisibilityState = PageVisibility.state;
                CommunicationService.initChannel(Immutable.fromJS(activeChannel));
                CommunicationService.selectChannel(activeChannel);
              } else {
                $state.go('root.communication.inbox');
              }
            },
            controllerAs: '$channelDetailCtrl',
            resolve: {
              activeChannel: function ($stateParams, CommunicationService) {
                var params =
                  $stateParams.id !== 'private' ? { organization_id: $stateParams.id } : {};
                return CommunicationService.getChannel($stateParams.channelId, params).catch(
                  angular.noop
                );
              },
            },
          },
        },
      })
      .state('root.communication.offeredplayers', {
        url: '/offeredplayers',
        views: {
          'content@root.communication': {
            templateUrl: 'communication/views/communicationOffered.html',
          },
        },
      });
  }

  var communicationModule = angular.module('app.communication', [
    'app.common',
    'app.general',
    'ngSanitize',
    'ui.router',
    'pascalprecht.translate',
  ]);
  communicationModule.config(communicationModuleConfig);
})();
