(function () {
  'use strict';

  angular
    .module('app.advancedSearch', [
      'app.common',
      'app.general',
      'ui.bootstrap',
      'ui.select',
      'rzModule',
    ])
    .provider('$advancedSearch', AdvancedSearchProvider)
    .config(config);

  function config($advancedSearchProvider) {
    var conditions = [
      {
        name: 'Season',
        value: 'SeasonCondition',
        mandatory: true,
      },
      {
        name: 'Position',
        value: 'PositionCondition',
      },
      {
        name: 'Shoots',
        value: 'ShootsCondition',
      },
      {
        name: 'Age',
        value: 'AgeCondition',
      },
      {
        name: 'Height',
        value: 'HeightCondition',
      },
      {
        name: 'Weight',
        value: 'WeightCondition',
      },
      {
        name: 'Date_of_birth',
        value: 'DateOfBirthCondition',
      },
      {
        name: 'Nationality',
        value: 'NationalityCondition',
      },
      {
        name: 'Passport',
        value: 'PassportCondition',
        disabled: true,
      },
      {
        name: 'League',
        value: 'LeagueCondition',
      },
      {
        name: 'Team',
        value: 'TeamCondition',
      },
      {
        name: 'GP',
        value: 'GPCondition',
      },
      {
        name: 'Free_agent',
        value: 'FreeAgentCondition',
      },
      // {
      //     name: 'Representation status',
      //     value: 'RepresentationStatusCondition'
      // },
      {
        name: 'Goals',
        value: 'GoalsCondition',
        restrict: 'player',
      },
      {
        name: 'Assists',
        value: 'AssistsCondition',
        restrict: 'player',
      },
      {
        name: 'TP',
        value: 'TPCondition',
        restrict: 'player',
      },
      {
        name: 'PIM',
        value: 'PIMCondition',
        restrict: 'player',
      },
      {
        name: 'PM',
        value: 'PMCondition',
        restrict: 'player',
      },
      {
        name: 'GAA',
        value: 'GAACondition',
        restrict: 'goalie',
      },
      {
        name: 'SVP',
        value: 'SVPCondition',
        restrict: 'goalie',
      },
    ];

    $advancedSearchProvider.registerCondition(conditions);
  }

  function AdvancedSearchProvider() {
    var conditionRegistry = [];

    this.registerCondition = function (condition) {
      if (!condition) {
        throw new Error('condition is required when register.');
      }

      if (Array.isArray(condition)) {
        conditionRegistry = condition;
      } else {
        conditionRegistry.push(condition);
      }
    };

    this.$get = [
      function AdvancedSearchFactory() {
        return {
          getConditions: getConditions,
          getRestiction: getRestiction,
        };

        function getConditions() {
          return conditionRegistry;
        }

        function getRestiction(conditionName) {
          var condition = _.find(conditionRegistry, { name: conditionName }) || {};
          return condition.restrict;
        }
      },
    ];
  }
})();
