(function () {
  'use strict';

  function configure($stateProvider) {
    $stateProvider
      .state('device', {
        url: '/device',
        abstract: true,
      })
      .state('device.unsupported', {
        url: '/unsupported',
        views: {
          '@': { template: '<browser-unsupported>' },
        },
      })
      .state('device.mobile', {
        url: '/phone',
        views: {
          '@': { template: '<device-mobile>' },
        },
      })
      .state('device.tablet', {
        url: '/tablet',
        views: {
          '@': { template: '<device-tablet>' },
        },
      });
  }

  angular
    .module('app.general', [
      'app.common',
      'angular-uuid',
      'ui.bootstrap',
      'ngMaterial',
      'ui.router',
      'pascalprecht.translate',
      'flow',
    ])
    .config(configure);
})();
